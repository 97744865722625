<!-- Book Detail -->
<script setup>
    import {Swiper, SwiperSlide} from "swiper/vue";
    import { EffectCards, Navigation} from 'swiper/modules';

    import "swiper/css";
    import 'swiper/css/effect-coverflow';
    import 'swiper/css/effect-cards';
    import 'swiper/css/navigation';

    const projectUrl = "https://seslikitapapi.loreandigital.com/";
</script>
<template>
    <!-- Item -->
    <div class="displayNone" ref="notificationContainer animate__animated animate__backInRight" id="notification-container"></div>

    <section class="container" style="margin-top: 125px">
      <button @click="goBack" class="back-button"><i class='bx bxs-left-arrow'></i></button>
    </section>
    <section class="relative pt-24 pb-24 lg:py-32 animate__animated animate__backInRight" style="padding-top: 30px; animation-duration: 0.5s; /* don't forget to set a duration! */">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src="/assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full " />
        </picture>
        <div class="container">
          <!-- Item -->
          <div class="md:flex md:flex-wrap">
            <!-- Image -->
            <figure class="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2">
              <img
                :src="`${projectUrl}${book.book_image}`"
                alt="item"
                class="cursor-pointer rounded-2.5xl"
              />

              <!-- Modal -->
              <div class="modal fade" id="imageModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog !my-0 flex h-full items-center justify-center p-4">
                  <img :src="`${projectUrl}${book.book_image}`" alt="item" />
                </div>

                <button
                  type="button"
                  class="btn-close absolute top-6 right-6"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-6 w-6 fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    />
                  </svg>
                </button>
              </div>
              <!-- end modal -->
            </figure>

            <!-- Details -->
            <div class="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
              <!-- Collection / Likes / Actions -->
              <div class="mb-3 flex">
                <!-- Collection -->
                <div class="flex items-center">
                  <a href="javascript:;" class="mr-2 text-sm font-bold text-white" style="color: #12b981">Onaylanmış Kitap</a>
                  <span
                    class="inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </span>
                </div>

                <!-- Likes / Actions -->
                <div class="ml-auto flex space-x-2">
               
                  <!-- Actions -->
                  <div
                    class="dropdown rounded-xl border border-jacarta-100 bg-white hover:bg-jacarta-100 dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-jacarta-600"
                  >
                    <a
                      href="javascript:;"
                      class="dropdown-toggle inline-flex h-10 w-10 items-center justify-center text-sm"
                      role="button"
                      id="collectionActions"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                    <i class='bx bxs-copy'></i>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-end z-10 hidden min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                      aria-labelledby="collectionActions"
                    >
                      <button
                        @click="copyPageLink"
                        style="color: white"
                        class="block w-full rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600"
                      >
                      <i class='bx bxs-copy' ></i> Paylaş (Bağlantıyı Kopyala)
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <h1 class="mb-4 font-display text-4xl font-semibold text-jacarta-700 dark:text-white">{{ book.name }} </h1>
              <p class="mb-10 dark:text-jacarta-300">
                {{ book.description }}
              </p>

              <div v-if="book.pdf_url" class="mb-4">
                <a target="_blank" :href="'https://seslikitapapi.loreandigital.com/'+book.pdf_url" class="btn btn-success inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-darkr table-play-button pdf-button"><i class='bx bxs-file-pdf'></i> PDF Görüntüle</a>
              </div>

              <!-- Creator / Owner -->
              <div class="mb-8 flex flex-wrap animate__animated animate__backInRight">
                <div class="mr-8 mb-4 flex">
                  <figure class="mr-4 shrink-0">
                    <router-link :to="{name: 'writerComponent', params: {id: writer.id}}">
                      <!-- <img :src="`${projectUrl}${book.writer ? book.writer.image : ''}`" alt="avatar 7" class="rounded-2lg" loading="lazy" /> -->
                      <img :src="`${projectUrl}${writer.image}`" alt="avatar 7" class="rounded-2lg book-detail-writer-image " loading="lazy" />
                      <div
                        class="absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                        data-tippy-content="Verified Collection"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-[.875rem] w-[.875rem] fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </router-link>
                  </figure>
                  <div class="flex flex-col justify-center">
                    <span class="block text-sm text-jacarta-400 dark:text-white"
                      > <strong>Yazar</strong></span
                    >
                    <router-link :to="{name: 'writerComponent', params: {id: writer.id}}" class="block text-accent">
                      <span class="text-sm font-bold"> {{ writer.name }} </span>
                    </router-link>
                  </div>
                </div>
              </div>

              <!-- Bid -->
              <div class="rounded-2lg border border-jacarta-100 bg-white p-8 dark:border-jacarta-600 dark:bg-jacarta-700" style="border:none; padding: 0px;">
                  <!-- Table -->
              <div class="scrollbar-custom overflow-x-auto animate__animated animate__backInRight">
                <div
                  role="table"
                  class="w-full border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white lg:rounded-2lg"
                >
                  <div class="flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600" role="row">
                    <!-- Column Başlıkları -->
                    <div class="w-[28%] py-3 px-4" role="columnheader">
                      <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100"
                        >Bölüm</span
                      >
                    </div>
                    <div class="w-[28%] py-3 px-4" role="columnheader">
                      <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100"
                        >Toplam Süre</span
                      >
                    </div>
                    <div class="w-[12%] py-3 px-4" role="columnheader">
                      <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100"
                        > Oynat </span
                      >
                    </div>
                    <!-- Column Başlıkları -->
                  </div>
                  
                  <!-- Bu kitabın bölümleri -->
                  <a v-for="part in parts" :key="part.id" href="javascript:;" class="flex transition-shadow hover:shadow-lg " role="row">
                    <div
                      class="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                      role="cell"
                    >
                      <span class="mr-2 lg:mr-4">{{ part.part_number }}</span>
                      <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                       {{ part.part_name }}
                      </span>
                    </div>
                    <div
                      class="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                      role="cell"
                    >
                      <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                        {{ part.voice_time }}
                      </span>
                    </div>
                    <div
                      class="flex w-[12%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                      role="cell"
                    >

                      <button v-if="$store.state.playingUrl == part.voice_url && $store.state.isPlaying" @click="stopPlay()" class="btn btn-success inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-darkr table-play-button" style="background: linear-gradient(45deg, rgb(26, 212, 26), green); box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;"><i class='bx bx-pause-circle'></i></button>
                      <button v-if="$store.state.playingUrl == part.voice_url && !$store.state.isPlaying" @click="startPlayer()" class="btn btn-success inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-darkr table-play-button" style="background: linear-gradient(45deg, rgb(26, 212, 26), green); box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;"><i class='bx bx-play-circle'></i></button>
                      <button v-if="$store.state.playingUrl != part.voice_url && !$store.state.isPlaying" @click="partPLay(book, part)" class="btn btn-success inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-darkr table-play-button"><i class='bx bx-play-circle'></i></button>
                      <button v-if="$store.state.playingUrl != part.voice_url && $store.state.isPlaying" @click="partPLay(book, part)" class="btn btn-success inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-darkr table-play-button"><i class='bx bx-play-circle'></i></button>
                    </div>
                  </a>
                   <!-- Bu kitabın bölümleri -->

                </div>
               </div>
              </div>
              <!-- end bid -->

              <!-- Oynat Butonu -- Bid -->
              <div
                class="rounded-2lg border border-jacarta-100 bg-white p-8 dark:border-jacarta-600 dark:bg-jacarta-700"
                style="border:none; padding-left: 0px; padding-right: 0px; background: transparent"
              >
                <a :key="book.id"
                  v-if="$store.state.bookId == book.id && $store.state.isPlaying"
                  @click="stopPlay()" style="cursor: pointer; background: linear-gradient(45deg, #1ad41a, green); box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;"
                  class="d-flex-btn inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  > <i class='bx bx-pause-circle start-btn'></i> Durdur</a
                >
                <a :key="book.id"
                  v-if="$store.state.bookId == book.id && !$store.state.isPlaying"
                  @click="startPlayer()" style="cursor: pointer; background: linear-gradient(45deg, #1ad41a, green); box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;"
                  class="d-flex-btn inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  > <i class='bx bx-play-circle start-btn'></i> Devam Et</a
                >
                <a
                  v-if="$store.state.bookId != book.id && !$store.state.isPlaying"
                  @click="changeBookAndPlay(book)" style="cursor: pointer"
                  class="d-flex-btn inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  > <i class='bx bx-play-circle start-btn'></i> Baştan Oynat</a
                >
                <a
                  v-if="$store.state.bookId != book.id && $store.state.isPlaying"
                  @click="changeBookAndPlay(book)" style="cursor: pointer"
                  class="d-flex-btn inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  > <i class='bx bx-play-circle start-btn'></i> Baştan Oynat</a
                >
              </div>
             <!-- Oynat Butonu -- Bid -->

            </div>
            <!-- end details -->
          </div>
        </div>
      </section>
      <!-- end item -->

     <!-- Önerilen Kitaplar -->
      <section class="py-24 dark:bg-jacarta-800">
            <div class="container">
                <h2 class="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white"> Bunlara da <span style="color: #8358ff">göz atabilirsiniz</span> </h2>
                <div class="relative">
                    <!-- Swiper Area -->
                    <!-- New Coverflow Swiper -->
            <Swiper 
                @swiper="onSwiper"
                :navigation="true"
                :effect="'navigation'"
                :space-between="50"
                :grabCursor="true"
                :breakpoints="{
                '400': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                '1324': {
                    slidesPerView: 4,
                   
                },
                }"
                :modules="[EffectCards, Navigation]"
                >
                <SwiperSlide v-for="(randomizeBook, i) in randomizeBooks" :key="i + 'A'">
                    <article>
                    <div
                      class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700"
                    >

                      <router-link :to="{name: 'kitap-detayi', params: {id: randomizeBook.id}}">
                        <span class="w-100">
                          <img
                            :src="`${projectUrl}${randomizeBook.book_image}`"
                            alt="item 1"
                            class="h-full w-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                        </span>
                      </router-link>
                   

                      <router-link
                      :to="{name: 'kitap-detayi', params: {id: randomizeBook.id}}"
                        class="mt-4 block font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent"
                      >
                        {{ randomizeBook.name }}
                    </router-link>

                      <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                        <div class="flex flex-wrap items-center">
                          <router-link :to="{name: 'writerComponent', params: {id: writer.id}}" class="mr-2 shrink-0">
                            <img :src="`${projectUrl}${randomizeBook.writer.image}`" alt="owner" class="h-5 w-5 rounded-full" />
                          </router-link>
                          <span class="mr-1 dark:text-jacarta-400">Yazar</span>
                          <router-link :to="{name: 'writerComponent', params: {id: writer.id}}" class="text-accent">
                            <span>{{ randomizeBook.writer.name }}</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </article>
                </SwiperSlide>
            </Swiper>
            <!-- New Coverflow Swiper Finish -->
                    <!-- Swiper Area -->
                </div>
            </div>
        </section>
        <!-- Önerilen Kitaplar -->
     
</template>

<script>
import axios from 'axios';
import emitter from '../../services/Emitter';
import { mapState } from 'vuex';

 export default {
    name: 'BookDetail',
    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },
    computed: {
    ...mapState(['yourStateProperty']),
    },
    mounted() {
      this.scrollToTop();
    },
    created() {
      window.scrollTo({top: 0});
      this.getBook();
      this.getRandomizeBooks();
      this.getParts();
      this.bookId = this.$refs.bookId;
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    data() {
      return {
        book: {},
        writer: {},
        randomizeBooks: {},
        parts: [],
        track: [],
        currentChapter: { audioUrl: '/assets/sound/melisfis.mp3' },
        isPlaying: false,
        stateIsPlaying: this.$store.state.stateIsPlaying,
        bookId: [],
      }
    },
    watch: {
      id(id) {
        console.log('id değişti');
        this.getBook(id);
        this.getRandomizeBooks(id);
        this.getParts(id);
        window.scrollTo({top: 0, behavior: 'smooth'});
      },
      'store.state.stateIsPlaying': function () {
          console.log('Oynatma durumu değişti:');
          if (this.$store.stateIsPlaying == true) {
            // Yürütülecek işlemler oynatma durumu true olduğunda
            this.$refs.myAudioPlayer.play();
            this.buttonText = 'Durdur';
          } else {
            this.$refs.myAudioPlayer.pause();
            this.buttonText = 'Başlat';
          }
        },
        $route() {
          // Vue Router ile sayfa değiştiğinde kullanıcıyı en yukarı çek
          this.scrollToTop();
        },
    },
    methods: {
      getBook() {
        axios.get(`https://seslikitapapi.loreandigital.com/api/book/${this.id}`)
        .then(response => {
          this.book = response.data; 
          console.log(this.$store.bookId);
          const writer_id = this.book.writer_id;
          return axios.get(`https://seslikitapapi.loreandigital.com/api/writer/${writer_id}`);
        })
        .then(response => {this.writer = response.data;})
        .catch(error => {console.error("API isteği başarısız", error)});
      },
      getRandomizeBooks() {
        axios.get('https://seslikitapapi.loreandigital.com/api/randomizeBooks')
        .then(response => {this.randomizeBooks = response.data})
        .catch(error => {console.error("API İsteği başarısız", error)});
      },
      getParts() {
        axios.get(`https://seslikitapapi.loreandigital.com/api/getParts/${this.id}`)
        .then(response => {this.parts = response.data})
        .catch(error => {console.error("API İsteği başarısız", error)});
      },
      goBack() {
        const router = this.$router;
        router.go(-1);
      },
      changeBookAndPlay(book) {
        axios.get(`https://seslikitapapi.loreandigital.com/api/getFirstPart/${book.id}`)
        .then(
          response => {this.track = response.data.voice_url; console.log(this.track); emitter.emit('send-music', [response.data, book]);})
        .catch(error => {console.error("API İsteği başarısız", error)});
        this.$store.state.bookId = this.book.id;
        this.$store.state.playingUrl = this.book.id;
        this.$store.state.playIcon = 'bx bx-pause-circle play-circle';
        // this.$store.commit('changeMomentTrackSrc', this.track);
        // this.$store.dispatch('playAudio', this.track);
      },
      partPLay(book, part) {
        axios.get(`https://seslikitapapi.loreandigital.com/api/getPart/${part.id}`)
        .then(
        response => {emitter.emit('send-part', [response.data, book]);})
        .catch(error => {console.error("API İsteği başarısız", error)});
      },
      stopPlay() {
        emitter.emit('music-stop');
        this.$store.state.playIcon = 'bx bx-play-circle play-circle';
        this.$store.state.isPlaying = false;
      },
      startPlayer() {
        emitter.emit('music-start');
        this.$store.state.playIcon = 'bx bx-play-circle play-circle';
        this.$store.state.isPlaying = true;
      },
      copyPageLink() {
        const pageLink = window.location.href;

        // Clipboard API kullanarak bağlantıyı kopyala
        navigator.clipboard.writeText(pageLink)
          .then(() => {
            console.log('Bağlantı kopyalandı:', pageLink);
            // Kopyalama başarılı
          })
          .catch((error) => {
            console.error('Bağlantı kopyalanırken bir hata oluştu:', error);
            // Kopyalama sırasında bir hata oluştu
          });

          const notificationContainer = document.getElementById("notification-container");
                notificationContainer.innerText = "Sayfa bağlantısı kopyalandı";
                notificationContainer.classList.remove("displayNone");
                notificationContainer.classList.add("displayBlock");
                // Ekstra stil ekleyebilir veya animasyonlar ekleyebilirsiniz.
                setTimeout(() => {
                    notificationContainer.innerText = '';
                    notificationContainer.classList.add("displayNone");
                }, 3000); // Bildirimi 3 saniye sonra kaldır
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      // playAudioAction(book) {
      //   this.$store.dispatch('playAudio', book);
      // },
    }
 }
</script>