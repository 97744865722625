<script setup>
    const projectUrl = "https://seslikitapapi.loreandigital.com/";
</script>

<template>
    <!-- Profile -->
    <!-- Banner -->
    <div class="relative">
        <img src="/assets/img/space.webp" style="width: 100%; filter: blur(17px); -webkit-filter: blur(17px);" alt="banner" class="h-[18.75rem] object-cover" />
    </div>
    <section class="container" style="margin-top: 0px">
      <button @click="goBack" class="back-button"><i class='bx bxs-left-arrow'></i></button>
    </section>
      <!-- end banner -->
    <section class="relative bg-light-base pb-12 pt-28 dark:bg-jacarta-800">
        <!-- Avatar -->
        <div class="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <figure class="relative">
            <img
              :src="`${projectUrl}${writer.image}`"
              alt="collection avatar"
              class="rounded-xl border-[5px] border-white dark:border-jacarta-600 writer-image-big  animate__animated animate__backInRight"
            />
            <div
              class=" animate__animated animate__backInRight absolute -right-3 bottom-0 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
              data-tippy-content="Verified Collection"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="h-[.875rem] w-[.875rem] fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
              </svg>
            </div>
          </figure>
        </div>

        <div class="container  animate__animated animate__backInRight">
          <div class="text-center">
            <h2 class="mb-2 font-display text-4xl font-medium text-jacarta-700 dark:text-white"> {{ writer.name }} </h2>
            <div class="mb-8">
              <a href="javascript:;" class="text-sm font-bold text-accent">Yazar</a>
            </div>


            <p class="mx-auto max-w-xl text-lg dark:text-jacarta-300">
              {{ writer.description }}
            </p>

            <div class="mt-6 flex items-center justify-center space-x-2.5">
              <div
                class="dropdown rounded-xl border border-jacarta-100 bg-white hover:bg-jacarta-100 dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-jacarta-600"
              >
                <p id="notification-text"></p>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <!-- end profile -->

      <!-- Books -->
      <section class="relative py-24">
      <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img src="/assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
      </picture>
      <div class="container">
        <h1 class="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white" style="padding-top: 10px; padding-bottom: 25px;">
            <i class='bx bx-book-reader' style="color: rgb(131, 88, 255)" ></i> Yazarın Kitapları
        </h1>

        <!-- Grid -->
        <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4">
            
            <article v-for="book in books" :key="book.id" class="animate__animated animate__backInLeft">
                    <div
                      class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700"
                    >
                      <router-link :to="{name: 'kitap-detayi', params: {id: book.id}}">
                        <span class="w-100">
                          <img
                            :src="`${projectUrl}${book.book_image}`"
                            alt="item 1"
                            class="h-full w-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                        </span>
                    </router-link>

                      <router-link
                        :to="`kitap-detayi/${book.id}`"
                        class="mt-4 block font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent"
                      >
                        {{ book.name }}
                      </router-link>

                      <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                        <div class="flex flex-wrap items-center">
                          <a href="javascript:;" class="mr-2 shrink-0">
                            <img :src="`${projectUrl}${book.writer.image}`" alt="owner" class="h-5 w-5 rounded-full" />
                          </a>
                          <span class="mr-1 dark:text-jacarta-400">Yazar</span>
                          <a href="javascript:;" class="text-accent">
                            <span>{{ book.writer.name }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>

          
         
        </div>
      </div>
    </section>
      <!-- Books and -->

</template>

<script>
import axios from 'axios';


export default {
  name: 'WriterComponent',
  mounted () {
    this.getWriter();
    this.getWriterBooks();
    window.scrollTo({top: 0, behavior: 'smooth'});

  }, 
  props: {
    id: {
        type: [String, Number],
        required: true
    }
  },
  data() {
    return {
        writer: [],
        books: [],
        notificationContainer: this.$refs.notificationContainer
    }
  },
  methods: {
    getWriterBooks() {
        axios.get(`https://seslikitapapi.loreandigital.com/api/getWriterBooks/${this.id}`)
        .then(response => {this.books = response.data})
        .catch(error => {console.error("API isteği başarısız", error)})
    },
    getWriter() {
        axios.get(`https://seslikitapapi.loreandigital.com/api/getWriter/${this.id}`)
        .then(response => {this.writer = response.data})
        .catch(error => {console.error("API isteği başarısız", error)});
        window.scrollTo({top: 0, behavior: 'smooth'});

    },
    goBack() {
        const router = this.$router;
        router.go(-1);
    },
  }
}
</script>