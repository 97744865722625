<template>
  <div>
    <HeaderComponent />
    <!-- <TrackerComponent /> -->
    <!-- <router-link to="/">Anasayfa</router-link>  -->
    <transition  enter-active-class="animate__animated animate__fadeIn"
          mode="out-in">
      <router-view></router-view>
    </transition>
    <FooterComponent />
    
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
// import TrackerComponent from './components/TrackerComponent.vue';
import 'animate.css';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/css';


export default {
  name: 'App',
  components: {
    HeaderComponent, 
    FooterComponent,
    // TrackerComponent,
    // Swiper
  },
}
</script>
 
<style>
 .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.01s ease-out;
  }
</style>

