<script setup>
    const projectUrl = "https://seslikitapapi.loreandigital.com/";

</script>
<template>
    <!-- Collections -->
    <section class="container" style="margin-top: 125px">
      <button @click="goBack" class="back-button"><i class='bx bxs-left-arrow'></i></button>
    </section>
    <section class="relative py-24">
      <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img src="/assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
      </picture>
      <div class="container">
        <h1 class="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white" style="padding-top: 10px; padding-bottom: 25px;">
            <i class='bx bx-search-alt' style="color: rgb(131, 88, 255)" ></i> Kitap Ara <span class="text-accent">{{ bookName }}</span>
        </h1>

        <!-- Grid -->
        <div class="grid">
          <p :key="'x'" ref="nullMessage" style="color: #f19191;
    font-size: 1em;
    text-align: center;
    font-weight: 100;"> {{ nullMessage }} </p>
        </div>
        <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4">
            <article v-for="book in books" :key="book.id" class="animate__animated animate__backInLeft">
                    <div
                      class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700"
                    >
                      <router-link :to="`/kitap-detayi/${book.id}`">
                        <span class="w-100">
                          <img
                            :src="`${projectUrl}${book.book_image}`"
                            alt="item 1"
                            class="h-full w-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                        </span>
                    </router-link>

                      <router-link
                        :to="`kitap-detayi/${book.id}`"
                        class="mt-4 block font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent"
                      >
                        {{ book.name }}
                      </router-link>

                      <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                        <div class="flex flex-wrap items-center">
                          <a href="javascript:;" class="mr-2 shrink-0">
                            <img :src="`${projectUrl}${book.writer.image}`" alt="owner" class="h-5 w-5 rounded-full" />
                          </a>
                          <span class="mr-1 dark:text-jacarta-400">Yazar</span>
                          <a href="javascript:;" class="text-accent">
                            <span>{{ book.writer.name }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
            </article>

          
         
        </div>
      </div>
    </section>
    <!-- end collections -->
</template>

<script>
import axios from 'axios';


export default {
  name: 'BookSearch',
  mounted () {
    this.getBooks();
    window.scrollTo({top: 0, behavior: 'smooth'});

  }, 
  props: {
      bookName: {
        type: [String, Number],
        required: true
      }
  },
  data() {
    return {
        books: [],
    }
  },
  watch: {
      bookName() {
        this.getBooks();
        window.scrollTo({top: 0, behavior: 'smooth'});

        console.log('gelen: '+ this.books);
        if (this.books.length === 0) {
          this.nullMessage = "Maalesef aradığınız kritere uygun kitap bulunamadı"
        } else {
          this.nullMessage = ""
        }
      },
  },
  methods: {
    getBooks() {
        axios.get(`https://seslikitapapi.loreandigital.com/api/searchBook/${this.bookName}`)
        .then(response => {
          this.books = response.data
          if (response.data.length === 0) {
          this.nullMessage = "Maalesef aradığınız kritere uygun kitap bulunamadı"
          } else {
            this.nullMessage = ""
          }
        })
        .catch(error => {console.error("API isteği başarısız", error)})

        if (this.books == null) {
          this.nullMessage = "Maalesef aradığınız kritere uygun kitap bulunamadı"
        } else {
          this.nullMessage = ""
        }
    },
    goBack() {
        const router = this.$router;
        router.go(-1);
    },
  }
}
</script>