<script setup>
    import {Swiper, SwiperSlide} from "swiper/vue";
    import { EffectCoverflow, Pagination, EffectCards, Navigation} from 'swiper/modules';

    import "swiper/css";
    import 'swiper/css/effect-coverflow';
    import 'swiper/css/effect-cards';
    import 'swiper/css/navigation';

    const projectUrl = "https://seslikitapapi.loreandigital.com/";
</script>

<!-- src/components/HomeComponent.vue -->
<template>
   <main>
        <section class="hero relative py-20 md:pt-32 ">
            <!-- Gradient Görsel -->
            <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
                <img src="assets/img/gradient.jpg" alt="gradient" class="w-full mobile-height-600" />
            </picture>
            <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
                <img src="assets/img/gradient_dark.jpg" alt="gradient dark" class="w-full" />
            </picture>
            <!-- Gradient Görsel Bitiş -->

            <!-- Main Search Container -->
            <div class="container">
                <div class="mx-auto max-w-2xl pt-24 text-center">
                    <h1 class="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl">
                            Dini Sesli Kitapları Dinle
                        <span class="animate-gradient">Sesli-Kitap</span>
                    </h1>
                    <!-- Search -->
                    <form @submit.prevent="bookSearchHome" class="relative mb-4">
                    
                    <input type="search" id="searchInputSecond" name="searchInputSecond" class="w-full rounded-2xl border border-jacarta-100 py-4 px-4 pl-10 text-md text-jacarta-700 placeholder-jacarta-300 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white" placeholder="Sesli Kitap, Yazar arayınız..." />
                        <span class="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="h-4 w-4 fill-jacarta-500 dark:fill-white">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                                d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
                            />
                            </svg>
                        </span>
                    </form>

                    <span class="dark:text-jacarta-300">Bu kategorilere göz atın: </span>
                    <router-link :key="popularCategory.id" v-for="popularCategory in popularCategories" :to="{name: 'exploreWithCategory', params: {id: popularCategory.id}}" class="text-accent hover:text-jacarta-700 dark:hover:text-white"> {{popularCategory.name}}, </router-link>
                </div>
            </div>
            <!-- Main Search Container -->


        </section>

        <div class="relative pb-16 sm:px-0 dark:bg-jacarta-900 mobile-padding-20">
            <!-- New Coverflow Swiper -->
            <Swiper 
                :space-between="50"
                @swiper="onSwiper"
                :effect="'coverflow'"
                :navigation="true"
                :grabCursor="true"
                :centeredSlides="true"
                :centeredSlidesBounds="true"
                :centerInsufficientSlides="true"
                :coverflowEffect="{
                rotate: 15,
                stretch: -10,
                depth: 100,
                modifier: 1,
                slideShadows: false,
                }"
                :breakpoints="{
                '400': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                '1324': {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
                }"
                :modules="[EffectCoverflow, Pagination, Navigation]"
                @slideChange="onSlideChange">
                <SwiperSlide style="padding: 40px 0px;" v-for="(mainBook, i) in mainBooks" :key="i + 'F'">
                    <article>
                <div
                  class="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700"
                >
                <router-link :to="`kitap-detayi/${mainBook.id}`">
                  <figure class="relative">
                    <a href="javascript:;">
                      <img
                        :src="`${projectUrl}${mainBook.book_image}`"
                        alt="item 1"
                        class="swiper-lazy h-[430px] w-full object-cover"
                        height="430"
                        width="379"
                      />
                      <div class="swiper-lazy-preloader"></div>
                    </a>
                  </figure>
                </router-link>
                
                  <div class="p-6">
                    <div class="flex">
                      <a href="javascript:;" class="shrink-0">
                        <img :src="`${projectUrl}${mainBook.writer.image}`" alt="avatar" class="mr-4 h-10 w-10 rounded-full" />
                      </a>
                      <div>
                        <a href="javascript:;" class="block">
                          <span
                            class="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white"
                            > {{ mainBook.name }} </span>
                        </a>
                        <router-link :to="`yazar-detayi/${mainBook.writer.id}`" class="text-2xs text-accent">{{mainBook.writer.name}}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
                </SwiperSlide>
            </Swiper>
            <!-- New Coverflow Swiper Finish -->
        </div>

        <!-- Son Çıkan Kitaplar -->
        <section class="py-24 dark:bg-jacarta-800">
            <div class="container">
                <h2 class="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white"> Son Çıkan <span style="color: #8358ff">Kitaplar</span> </h2>
                <div class="relative">
                    <!-- Swiper Area -->
                    <!-- New Coverflow Swiper -->
            <Swiper 
                
                @swiper="onSwiper"
                :navigation="true"
                :effect="'navigation'"
                :space-between="50"
                :grabCursor="true"
                :breakpoints="{
                '400': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                '1324': {
                    slidesPerView: 4,
                   
                },
                }"
                :modules="[EffectCards, Navigation]"
                >
                <SwiperSlide v-for="(lastBook, i) in lastBooks" :key="i + 'A'">
                    <article>
                    <div
                      class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700"
                    >
                      <router-link :to="`kitap-detayi/${lastBook.id}`">
                        <span class="w-100">
                          <img
                            :src="`${projectUrl}${lastBook.book_image}`"
                            alt="item 1"
                            class="h-full w-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                        </span>
                      </router-link>

                      <router-link :to="`kitap-detayi/${lastBook.id}`"
                        class="mt-4 block font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent"
                      >
                        {{ lastBook.name }}
                      </router-link>

                      <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                        <div class="flex flex-wrap items-center">
                          <router-link :to="`yazar-detayi/${lastBook.writer.id}`" class="mr-2 shrink-0">
                            <img :src="`${projectUrl}${lastBook.writer.image}`" alt="owner" class="h-5 w-5 rounded-full" />
                          </router-link>
                          <span class="mr-1 dark:text-jacarta-400">Yazar</span>
                          <router-link :to="`yazar-detayi/${lastBook.writer.id}`" class="text-accent">
                            <span>{{ lastBook.writer.name }}</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </article>
                </SwiperSlide>
            </Swiper>
            <!-- New Coverflow Swiper Finish -->
                    <!-- Swiper Area -->
                </div>
            </div>
        </section>
        <!-- Son Çıkan Kitaplar -->

        <!-- Kategorileri Ara -->
        <section class="py-24 mobile-padding-20">
            <h2 class="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white"><span style="color: #8358ff">Kategorilere</span> Göz Atın</h2>

            <!-- Slider -->
            <div class="relative">
            <div class="swiper centered-slider !pb-5">
                <div class="swiper-wrapper">
                    <Swiper 
                
                @swiper="onSwiper"
                :navigation="true"
                :effect="'navigation'"
                :space-between="20"
                :grabCursor="true"
                :breakpoints="{
                '400': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '640': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                '1324': {
                    slidesPerView: 4,
                   
                },
                }"
                :modules="[EffectCards, Navigation]"
                >
                <SwiperSlide v-for="(category, i) in categories" :key="i + 'B'">
                    <article>
                    <router-link
                        :to="`kesfet-kategori/${category.id}`"
                        class="block rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700"
                    >
                        <figure class="rounded-t-[0.625rem] bg-green">
                        <img
                            :src="`${projectUrl}${category.image}`"
                            alt="item 1"
                            class="w-full rounded-[0.625rem]"
                            loading="lazy"
                        />
                        </figure>
                        <div class="mt-4 text-center">
                        <span class="font-display text-lg text-jacarta-700 dark:text-white">{{ category.name }}</span>
                        </div>
                      </router-link>
                    </article>
                </SwiperSlide>
            </Swiper>
                </div>
            </div>
            </div>
        </section>
        <!-- Kategorileri Ara Bitiş -->

      <!-- Top Collections -->
      <section class="relative py-24 dark:bg-jacarta-800">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src="assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
        </picture>
        <div class="container">
          <div class="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            <h2 class="inline">En çok </h2>
            <div class="dropdown inline cursor-pointer">
              <button
                class="dropdown-toggle inline-flex items-center text-accent"
                type="button"
                id="collectionSort"
                aria-expanded="false"
              >
                dinlenen kitaplar
              </button>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4">
            

            <div v-for="popularBook in popularBooks" :key="popularBook.id + 'y'" class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <router-link :to="`kitap-detayi/${popularBook.id}`" class="relative block">
                  <img :src="`${projectUrl}${popularBook.book_image}`" alt="avatar 1" class="rounded-2lg best-books-image" loading="lazy" />
                </router-link>
              </figure>
              <div>
                <router-link :to="`kitap-detayi/${popularBook.id}`" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white"
                    >{{ popularBook.name }}</span
                  >
                </router-link>
                <router-link :to="{name: 'writerComponent', params: {id: popularBook.writer.id}}" class="block">
                  <span class="text-accent text-jacarta-700 hover:text-accent"
                    >{{ popularBook.writer.name }}</span
                  >
                </router-link>
              </div>
            </div>


        </div>
    </div>
    
      </section>
      <!-- end top collections -->


       <!-- Live Auctions -->
       <section class="py-24 dark:bg-jacarta-900">
        <div class="container">
          <h2 class="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            <span
              class="mr-1 inline-block h-6 w-6 animate-heartBeat bg-contain bg-center text-xl"
              style="
                background-image: url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png);
              "
            ></span>
            Yazarlar
          </h2>
          <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">

            <!-- Yazar Başlangıç -->
            <article v-for="popularWriter in popularWriters" :key="popularWriter.id + 'uo'">
              <div class="block rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                <figure class="relative">
                  <router-link :to="{name: 'writerComponent', params: {id: popularWriter.id}}">
                    <img
                      :src="`${projectUrl}${popularWriter.image}`"
                      alt="item 8"
                      class="w-full rounded-[0.625rem] list-writer-image"
                      loading="lazy"
                    />
                  </router-link>

                </figure>
                <div class="mt-7 flex items-center justify-between">
                  <a href="javascript:;">
                    <span class="font-display text-base text-jacarta-700 hover:text-accent dark:text-white"
                      >{{ popularWriter.name }}</span
                    >
                  </a>

                </div>
                <div class="mt-2 text-sm">
                  <span class="text-jacarta-700 dark:text-jacarta-100"> {{ popularWriter.description }} </span>
                </div>

                <div class="mt-4 flex items-center justify-between">
                  <router-link
                    :to="{name: 'writerComponent', params: {id: popularWriter.id}}"
                    class="font-display text-sm font-semibold text-accent author-detail-btn"

                  >
                    <i class="bx bxs-show" style="font-size: 25px"></i> Yazar Profili
                  </router-link>

                </div>
              </div>
            </article>
            <!-- Yazar Bitiş -->
           
          </div>

          <!-- <div class="mt-10 text-center">
            <a
              href="javascript:;"
              class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >Tüm Yazarları Görüntüle</a
            >
          </div> -->
        </div>
      </section>
      <!-- end live auctions -->

      <!-- Process / Newsletter -->
      <section class="relative py-24 dark:bg-jacarta-800">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src="assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
        </picture>
        <div class="container">
          <h2 class="mb-16 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            Sesli Kitap Nasıl Çalışıyor?
          </h2>
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">
            <div class="text-center">
              <div class="mb-6 inline-flex rounded-full bg-[#CDBCFF] p-3">
                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-accent">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-5 w-5 fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"
                    />
                  </svg>
                </div>
              </div>
              <h3 class="mb-4 font-display text-lg text-jacarta-700 dark:text-white">Dilediğiniz sesli kitabı seçin</h3>
              <p class="dark:text-jacarta-300">
                Geniş kütüphanemiz içinde en sevdiğiniz bilgi dolu kitabı seçiniz.
              </p>
            </div>
            <div class="text-center">
              <div class="mb-6 inline-flex rounded-full bg-[#C4F2E3] p-3">
                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-5 w-5 fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                  </svg>
                </div>
              </div>
              <h3 class="mb-4 font-display text-lg text-jacarta-700 dark:text-white">Dinlemeye Başlayın</h3>
              <p class="dark:text-jacarta-300">
                Üyelik gerektirmeyen Sesli Kitap, en hızlı dini kitap dinleyebileceğiniz platform olarak karşınızda.
              </p>
            </div>
            <div class="text-center">
              <div class="mb-6 inline-flex rounded-full bg-[#CDDFFB] p-3">
                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-blue">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-5 w-5 fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M17.409 19c-.776-2.399-2.277-3.885-4.266-5.602A10.954 10.954 0 0 1 20 11V3h1.008c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3H6V1h2v4H4v7c5.22 0 9.662 2.462 11.313 7h2.096zM18 1v4h-8V3h6V1h2zm-1.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                    />
                  </svg>
                </div>
              </div>
              <h3 class="mb-4 font-display text-lg text-jacarta-700 dark:text-white">Hiçbir Ücret Ödemeyin</h3>
              <p class="dark:text-jacarta-300">
                Verdiğimiz bu hizmet karşılığında hiçbir ücret talep etmeksizin sizleri bu değerli bilgilere
                kavuşturmak için canla başla çalışıyoruz.
              </p>
            </div>
            <div class="text-center">
              <div class="mb-6 inline-flex rounded-full bg-[#FFD0D0] p-3">
                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-5 w-5 fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm2.828 8.486a2 2 0 1 0 2.828-2.829 2 2 0 0 0-2.828 2.829z"
                    />
                  </svg>
                </div>
              </div>
              <h3 class="mb-4 font-display text-lg text-jacarta-700 dark:text-white">Geri dönüşlerinizle büyüyoruz</h3>
              <p class="dark:text-jacarta-300">
                Sizden tek ricamız olan olumlu veya olumsuz geri dönüşleriniz, yazılım ve genel işleyiş sürecimizi doğrudan geliştiriyor!
              </p>
            </div>
          </div>

          <p class="mx-auto mt-20 max-w-2xl text-center text-lg text-jacarta-700 dark:text-white">
            Size ulaşmamızı isterseniz bu kısımdan iletişim sayfamıza giderek form gönderebilir veya diğer iletişim 
            seçeneklerine göz atabilirsiniz.
          </p>

          <div class="mx-auto mt-7 max-w-md text-center">
            <form class="relative">
              <router-link to="/iletisim" class=" top-2 right-2 rounded-full bg-accent px-6 py-2 font-display text-sm text-white hover:bg-accent-dark">
                İletişim
              </router-link>
            </form>
          </div>
        </div>
      </section>
      <!-- end process / newsletter -->

       <!-- Partners -->
       <div class="bg-light-base dark:bg-jacarta-800">
        <div class="container">
          <div class="grid grid-cols-2 py-8 sm:grid-cols-5">
            <a href="javascript:;"><img src="assets/img/partners/favorispor-logo.png" style="max-width: 100px; filter: grayscale(1) opacity(0.2);" alt="partner 1" /> </a>
            <a href="javascript:;"><img src="assets/img/partners/favorispor-logo.png" style="max-width: 100px; filter: grayscale(1) opacity(0.2);" alt="partner 1" /> </a>
            <a href="javascript:;"><img src="assets/img/partners/favorispor-logo.png" style="max-width: 100px; filter: grayscale(1) opacity(0.2);" alt="partner 1" /> </a>
            <a href="javascript:;"><img src="assets/img/partners/favorispor-logo.png" style="max-width: 100px; filter: grayscale(1) opacity(0.2);" alt="partner 1" /> </a>
            <a href="javascript:;"><img src="assets/img/partners/favorispor-logo.png" style="max-width: 100px; filter: grayscale(1) opacity(0.2);" alt="partner 1" /> </a>
          </div>
        </div>
      </div>
      

   </main>
</template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'HomeComponent',

    mounted() {
        this.getBooks();
        this.getLastBooks();
        this.getCategories();
        this.getPopularBooks();
        this.getPopularWriters();
        this.getPopularCategories();
    },
    data() {
        return {
            mainBooks: [],
            lastBooks: [],
            categories: [],
            popularBooks: [],
            popularWriters: [],
            popularCategories: []
        }
    },
    methods: {
        getBooks() {
            axios.get('https://seslikitapapi.loreandigital.com/api/mainBooks')
            .then(response => {this.mainBooks = response.data})
            .catch(error => {console.error('API isteği başarısız', error);});
        },

        getLastBooks() {
            axios.get('https://seslikitapapi.loreandigital.com/api/lastBooks')
            .then(response => {this.lastBooks = response.data})
            .catch(error => {console.error('API isteği başarısız', error);});
        },

        getCategories() {
          axios.get('https://seslikitapapi.loreandigital.com/api/categories')
          .then(response => {this.categories = response.data})
          .catch(error => {console.error("API isteği başarısız", error)});
        },

        getPopularWriters() {
          axios.get('https://seslikitapapi.loreandigital.com/api/topWriters')
          .then(response => {this.popularWriters = response.data})
          .catch(error => {console.error("API isteği başarısız", error)});
        },

        getPopularBooks() {
          axios.get('https://seslikitapapi.loreandigital.com/api/popularBooks')
          .then(response => {this.popularBooks = response.data})
          .catch(error => {console.error("API isteği başarısız", error)});
        },

        getPopularCategories() {
          axios.get('https://seslikitapapi.loreandigital.com/api/getPopularCategories')
          .then(response => {this.popularCategories = response.data})
          .catch(error => {console.error("API isteği başarısız", error)});
        },

        bookSearchHome() {
          const bookName = document.getElementById('searchInputSecond').value;
          this.$router.push({ name: 'search', params: { bookName } });
        }
    }
  }
  </script>