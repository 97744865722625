import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
           bookId: [],
           partId: [],
           stateIsPlaying: false,
           momentTrackSrc: [],
           isPlaying: false,
           playingUrl: [],
           playIcon: 'bx bx-pause-circle play-circle'
        }
    },
    mutations: {
        setBookId(state, newBookId) {
            state.bookId = newBookId;
        },
        setPlaying(state, newStatus) {
            state.isPlaying = newStatus;
        },
        changeStateIsPlaying(state, newPlayBoolean){
            state.stateIsPlaying = newPlayBoolean
        },
        changeMomentTrackSrc(state, newTrackSrc) {
            state.momentTrackSrc = newTrackSrc
        }
    }
});

export default store;