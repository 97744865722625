import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from './store';

// createApp(App).mount('#app')

// Pages Import
import HomeComponent from './components/pages/HomeComponent.vue'
import BookDetail from './components/pages/BookDetail.vue'
import BookSearch from './components/pages/BookSearch.vue'
import ExploreComponent from './components/pages/ExploreComponent.vue'
import ExploreWithCategory from './components/pages/ExploreWithCategory.vue'
import ContactComponent from './components/pages/ContactComponent.vue'
import WriterComponent from './components/pages/WriterComponent.vue'
import AboutComponent from './components/pages/AboutComponent.vue'

const routes = [
    { 
      path: '/', 
      name: 'home',
      component: HomeComponent 
    },
    { 
      path: '/kitap-detayi/:id', 
      name: 'kitap-detayi',
      component: BookDetail,
      props: true
    },
    {
      path: '/kitap-ara/:bookName',
      name: 'search',
      component: BookSearch,
      props: true
    },
    {
      path: '/kesfet',
      name: 'explore',
      component: ExploreComponent
    },
    {
      path: '/kesfet-kategori/:id',
      name: 'exploreWithCategory',
      component: ExploreWithCategory,
      props: true
    },
    {
      path: '/iletisim',
      name: 'contactComponent',
      component: ContactComponent,
    },  
    {
      path: '/yazar-detayi/:id',
      name: 'writerComponent',
      component: WriterComponent,
      props: true
    },  
    {
      path: '/hakkimizda',
      name: 'aboutComponent',
      component: AboutComponent
    },  
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(router).use(store).mount('#app');