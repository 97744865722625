<template>
    <!-- Contact -->
    <section class="container" style="margin-top: 125px">
     <button @click="goBack" class="back-button"><i class='bx bxs-left-arrow'></i></button>
   </section>
   <!-- Page title -->
   <section class="relative animate__animated animate__backInRight">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src="/assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
        </picture>
        <div class="container">
          <!-- Page Title -->
          <div class="mx-auto max-w-2xl py-16 text-center">
            <h1 class="mb-8 font-display text-4xl font-medium text-jacarta-700 dark:text-white"> <span class="text-accent">İlmin Kapısı</span> Hakkında</h1>
            <p class="text-lg leading-normal dark:text-jacarta-300">
              {{ setting.about }}
            </p>
          </div>
        </div>
      </section>
</template>

<script>
   import axios from 'axios';

   export default {
       name: 'AboutComponent',
       created() {
           window.scrollTo({top: 0, behavior: 'smooth'});
           this.getSetting();
       },
       data() {
           return {
               setting: []
           }
       },
       methods: {
           getSetting() {
               axios.get(`https://seslikitapapi.loreandigital.com/api/getSetting`)
               .then(response => {
                   this.setting = response.data;
                   console.log(response.data)
               })
               .catch(error => {console.log(error)});
           },
           goBack() {
             const router = this.$router;
             router.go(-1);
           },
       }
   }
</script>