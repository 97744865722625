<script setup>
    const projectUrl = "https://seslikitapapi.loreandigital.com/";

</script>
<template>
    <!-- Collections -->
    <section class="container" style="margin-top: 125px">
      <button @click="goBack" class="back-button"><i class='bx bxs-left-arrow'></i></button>
    </section>
    <section class="relative py-24 animate__animated animate__backInRight" style="padding-top: 10px">
      <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img src="/assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
      </picture>
      <div class="container">
        <h1 class="text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white"  style="padding-top: 10px; padding-bottom: 25px;">
            <i class='bx bx-book-reader' style="color: rgb(131, 88, 255)" ></i> Keşfet
        </h1>

        <!-- Filters -->
        <div class="mb-8 flex flex-wrap items-center justify-between">
          <ul class="flex flex-wrap items-center">
            <li class="my-1 mr-2.5">
                <router-link style="background: #8358ff; color: white"
                to="/kesfet"
                class="group flex h-9 items-center justify-center rounded-lg bg-jacarta-100 px-4 font-display text-sm font-semibold text-jacarta-700 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:bg-jacarta-700 dark:text-white dark:hover:bg-accent"
                >Tümü
              </router-link>
              
            </li>

           
            <li class="my-1 mr-2.5" v-for="category in categories" :key="category.id">
              <router-link :key="category.id+'x'" style="background: #8358ff;" v-if="choosedCategory == category.id" :to="{name: 'exploreWithCategory', params: {id: category.id}}" class="categories group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                <i class='bx bx-book-open explore-category-icon' style="color: white!important;"></i>
                <span style="color: white">{{ category.name }}</span>
              </router-link>
              <router-link v-else :to="{name: 'exploreWithCategory', params: {id: category.id}}" class="categories group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                <i class='bx bx-book-open explore-category-icon'></i>
                <span>{{ category.name }}</span>
              </router-link>
            </li>
            
           
          </ul>
        </div>

        <!-- Grid -->
        <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4">

            <article v-for="book in books" :key="book.id" class="animate__animated animate__backInLeft">
                    <div
                      class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700"
                    >
                      <router-link :to="`/kitap-detayi/${book.id}`">
                        <span class="w-100">
                          <img
                            :src="`${projectUrl}${book.book_image}`"
                            alt="item 1"
                            class="h-full w-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                        </span>
                      </router-link>

                      <router-link
                        :to="`kitap-detayi/${book.id}`"
                        class="mt-4 block font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent"
                      >
                        {{ book.name }}
                      </router-link>

                      <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                        <div class="flex flex-wrap items-center">
                          <a href="javascript:;" class="mr-2 shrink-0">
                            <img :src="`${projectUrl}${book.writer.image}`" alt="owner" class="h-5 w-5 rounded-full" />
                          </a>
                          <span class="mr-1 dark:text-jacarta-400">Yazar</span>
                          <a href="javascript:;" class="text-accent">
                            <span>{{ book.writer.name }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
            </article>

          
         
        </div>
      </div>
    </section>
    <!-- end collections -->
</template>

<script>
import axios from 'axios';


export default {
  name: 'exploreWithCategory',
  props: {
      id: {
        type: [String, Number],
        required: true
      }
  },
  created () {
    window.scrollTo({top: 0});
    this.getCategories();
    this.getBooks();
    window.scrollTo({top: 0, behavior: 'smooth'});

  }, 
  data() {
    return {
        categories: [],
        books: [],
        choosedCategory: this.id
    }
  },
  watch: {
      id(id) {
        console.log('id değişti');
        this.getBooks(id);
        this.choosedCategory = id;
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
  },
  methods: {
    getCategories() {
        axios.get('https://seslikitapapi.loreandigital.com/api/categories')
        .then(response => {this.categories = response.data})
        .catch(error => {console.error("API isteği başarısız", error)})
    },
    getBooks() {
        axios.get(`https://seslikitapapi.loreandigital.com/api/exploreWithCategory/${this.id}`)
        .then(response => {this.books = response.data})
        .catch(error => {console.error("API isteği başarısız", error)})
    },
    goBack() {
        const router = this.$router;
        router.go(-1);
    },
  }
}
</script>